<template>
  <div>
    <b-table
      striped
      hover
      responsive
      :fields="fields"
      :items="adsReport.data"
    >
      <template #cell(brand)="data">
        <div>{{ data.item.brand }}</div>
        <small class="text-primary">{{ data.item.department }}</small>
      </template>
      <template #cell(ads)="data">
        <b-row>
          <b-col>
            <div
              v-if="data.item.notes"
              v-b-tooltip.hover
              :title="data.item.notes"
            >
              <div>{{ data.item.ads }}</div>
            </div>
            <div
              v-else
              v-b-tooltip.hover
              title="Reklam ile ilgili not bulunamadı."
            >
              <div>{{ data.item.ads }}</div>
            </div>
          </b-col>
          <b-col
            v-if="data.item.media.length > 0"
            cols="auto"
            class="text-right"
          >
            <b-button
              :id="'media-popover' + data.item.id"
              title="Popover on top"
              variant="flat-primary"
              class="btn-icon"
              pill
              size="sm"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
            <b-popover
              :target="'media-popover' + data.item.id"
              title="Görsel"
              triggers="click"
            >
              <ads-media :images="data.item.media" />
            </b-popover>
          </b-col>
        </b-row>
      </template>
      <template #cell(sdate)="data">
        {{ moment(data.item.sdate).format('DD.MM.YYYY') }}
      </template>
      <template #cell(edate)="data">
        {{ moment(data.item.edate).format('DD.MM.YYYY') }}
      </template>
      <template #cell(access)="data">
        {{ data.item.access | toNumber }}
      </template>
      <template #cell(form_count)="data">
        {{ data.item.form_count | toNumber }}
      </template>
      <template #cell(spent_per)="data">
        {{ data.item.spent_per | toCurrency }} ₺
      </template>
      <template #cell(spent)="data">
        {{ data.item.spent | toCurrency }} ₺
      </template>
      <template #cell(budget)="data">
        <div v-if="data.item.budget">
          {{ data.item.budget | toCurrency }} ₺
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #custom-foot>
        <b-tr>
          <b-th colspan="3">
            Toplam
          </b-th>
          <b-th class="text-right">
            {{ adsReport.totals.access | toNumber }}
          </b-th>
          <b-th class="text-right">
            {{ adsReport.totals.form_count | toNumber }}
          </b-th>
          <b-th class="text-right">
            {{ adsReport.totals.spent_per | toCurrency }} ₺
          </b-th>
          <b-th class="text-right">
            {{ adsReport.totals.spent | toCurrency }} ₺
          </b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BTr, BTh, BButton, VBTooltip, VBPopover, BPopover, BRow, BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import AdsMedia from '@/views/Reports/Marketing/components/AdsMedia'

export default {
  name: 'AdsReportTable',
  components: {
    BTable,
    BTr,
    BTh,
    BButton,
    AdsMedia,
    BPopover,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      fields: [
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'ads', label: 'Reklam', sortable: true, thClass: 'align-middle', tdClass: '',
        },
        {
          key: 'ad_type', label: 'Reklam Tipi', sortable: true, thClass: 'align-middle', tdClass: '',
        },
        {
          key: 'access', label: 'Erişim', sortable: true, thClass: 'align-middle text-right', tdClass: 'text-right',
        },
        {
          key: 'form_count', label: 'Form Adedi', sortable: true, thClass: 'align-middle text-right', tdClass: 'text-right',
        },
        {
          key: 'spent_per', label: 'Form Başına Ücret', sortable: true, thClass: 'align-middle text-right', tdClass: 'text-right',
        },
        {
          key: 'spent', label: 'Toplam Harcama', sortable: true, thClass: 'align-middle text-right', tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    adsReport() {
      return this.$store.getters['marketingAdsReport/getAdsReport']
    },
  },
}
</script>
