<template>
  <div>
    <b-carousel
      id="carousel-example-generic"
      controls
      :indicators="false"
      :interval="0"
    >
      <b-carousel-slide
        v-for="(image,ikey) in images"
        :key="ikey"
        :img-src="$store.state.app.baseURL + '/media/marketing/ads/' + image.filename"
      />
    </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  name: 'AdsMedia',
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: ['images'],
}
</script>

<style scoped>

</style>
