<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group
              label="Marka"
              label-for="brand"
            >
              <v-select
                id="brand"
                v-model="id_com_brand"
                :options="brands"
                label="title"
                :reduce="brand => brand.id"
                placeholder="Marka"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group
              label="Departman"
              label-for="department"
            >
              <v-select
                id="department"
                v-model="id_com_department"
                :options="departments"
                label="title"
                :reduce="department => department.id"
                placeholder="Departman"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group
              label="Reklam Tipi"
              label-for="ads_type"
            >
              <v-select
                id="ads_type"
                v-model="id_com_marketing_ad_types"
                :options="adTypes"
                label="title"
                :reduce="adType => adType.id"
                placeholder="Reklam Tipi"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group
              label="Platform"
              label-for="ads_platform"
            >
              <v-select
                id="ads_platform"
                v-model="id_com_marketing_ad_platforms"
                :options="adPlatforms"
                label="title"
                :reduce="adPlatform => adPlatform.id"
                placeholder="Platform"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="6"
          >
            <b-form-group
              label="Başlangıç Tarihi"
              label-for="sdate"
            >
              <b-form-datepicker
                id="sdate"
                v-model="sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="6"
          >
            <b-form-group
              label="Bitiş Tarihi"
              label-for="edate"
            >
              <b-form-datepicker
                id="edate"
                v-model="edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <b-button
              variant="primary"
              @click="getReport"
            >
              <FeatherIcon icon="BarChartIcon" />
              <span class="align-middle">Raporu Görüntüle</span>
            </b-button>
            <b-button
              v-if="adsReport.data.length > 0"
              variant="success"
              :href="downloadExcel"
              target="_blank"
              class="ml-1"
            >
              <FeatherIcon icon="DownloadIcon" />
              <span class="align-middle">Excel İndir</span>
            </b-button>
            <b-button
              v-if="adsReport.data.length > 0"
              variant="danger"
              :href="downloadPDF"
              target="_blank"
              class="ml-1"
            >
              <FeatherIcon icon="DownloadIcon" />
              <span class="align-middle">PDF İndir</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col
      v-if="adsReport.data.length > 0"
      cols="12"
    >
      <b-card>
        <ads-report-table />
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import AdsReportTable from '@/views/Reports/Marketing/components/AdsReportTable'

export default {
  name: 'AdsReport',
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    BButton,
    vSelect,
    AdsReportTable,
  },
  data() {
    return {
      id_com_brand: null,
      id_com_department: null,
      id_com_marketing_ad_types: null,
      id_com_marketing_ad_platforms: null,
      sdate: null,
      edate: null,
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    adTypes() {
      return this.$store.getters['marketingAdTypes/getMarketing_ad_types']
    },
    adPlatforms() {
      return this.$store.getters['marketingAdPlatforms/getMarketing_ad_platforms']
    },
    adsReport() {
      return this.$store.getters['marketingAdsReport/getAdsReport']
    },
    downloadExcel() {
      const urlParams2 = []
      if (this.sdate) {
        urlParams2.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams2.push(`edate=${this.edate}`)
      }
      if (this.id_com_brand) {
        urlParams2.push(`id_com_brand=${this.id_com_brand}`)
      }
      if (this.id_com_department) {
        urlParams2.push(`id_com_department=${this.id_com_department}`)
      }
      if (this.id_com_marketing_ad_types) {
        urlParams2.push(`id_com_marketing_ad_types=${this.id_com_marketing_ad_types}`)
      }
      if (this.id_com_marketing_ad_platforms) {
        urlParams2.push(`id_com_marketing_ad_platforms=${this.id_com_marketing_ad_platforms}`)
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/marketing/ads/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    downloadPDF() {
      const urlParams2 = []
      if (this.sdate) {
        urlParams2.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams2.push(`edate=${this.edate}`)
      }
      if (this.id_com_brand) {
        urlParams2.push(`id_com_brand=${this.id_com_brand}`)
      }
      if (this.id_com_department) {
        urlParams2.push(`id_com_department=${this.id_com_department}`)
      }
      if (this.id_com_marketing_ad_types) {
        urlParams2.push(`id_com_marketing_ad_types=${this.id_com_marketing_ad_types}`)
      }
      if (this.id_com_marketing_ad_platforms) {
        urlParams2.push(`id_com_marketing_ad_platforms=${this.id_com_marketing_ad_platforms}`)
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/marketing/ads/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  created() {
    this.getBrands()
    this.getDepartment()
    this.getAdTypes()
    this.getAdPlatforms()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
      })
    },
    getDepartment() {
      this.$store.dispatch('departments/departmentsList', {
        select: [
          'com_department.id AS id',
          'com_department.title AS title',
        ],
      })
    },
    getAdTypes() {
      this.$store.dispatch('marketingAdTypes/marketing_ad_typesList', {
        select: [
          'com_marketing_ad_types.id AS id',
          'com_marketing_ad_types.title AS title',
        ],
      })
    },
    getAdPlatforms() {
      this.$store.dispatch('marketingAdPlatforms/marketing_ad_platformsList', {
        select: [
          'com_marketing_ad_platforms.id AS id',
          'com_marketing_ad_platforms.title AS title',
        ],
      })
    },
    getReport() {
      this.$store.dispatch('marketingAdsReport/adsReport', {
        id_com_brand: this.id_com_brand,
        id_com_department: this.id_com_department,
        id_com_marketing_ad_types: this.id_com_marketing_ad_types,
        id_com_marketing_ad_platforms: this.id_com_marketing_ad_platforms,
        sdate: this.sdate,
        edate: this.edate,
      })
    },
  },
}
</script>
